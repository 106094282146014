import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post('https://searchatnow.com/api/api/admin/login', {
            email,
            password,
        });

        if (response.data && response.data.token) {
            const { token, admin } = response.data;

            // Save the token and admin information to local storage
            localStorage.setItem('adminToken', token);
            localStorage.setItem('admin', JSON.stringify(admin));

            console.log('Login successful', token);

            // Redirect to the "/admins" route
            navigate('/admins');
        } else {
            setError('Login failed. No data in the response.');
        }
    } catch (error) {
        setError(error.response.data.error);
        console.error('Error during login:', error);
    }
};


  return (
    <div>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <h1 className="text-center">Admin Login</h1>
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-md-8 col-lg-7 col-xl-6">
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                className="img-fluid"
                alt="Phone image"
              />
            </div>
            <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
              <form>
                {/* Email input with autoFocus */}
                <div className="form-outline mb-4">
                  <input
                    type="email"
                    id="form1Example13"
                    className={`form-control form-control-lg ${error && "is-invalid"}`}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                  />
                  <label className="form-label" htmlFor="form1Example13">
                    Email address
                  </label>
                </div>

                <div className="form-outline mb-4">
                  <input
                    type="password"
                    id="form1Example23"
                    className={`form-control form-control-lg ${error && "is-invalid"}`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label className="form-label" htmlFor="form1Example23">
                    Password
                  </label>
                </div>

                {error && <p className="text-danger">{error}</p>}

                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block"
                  onClick={handleLogin}
                >
                  Sign in
                </button>

              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AdminLogin;
