import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SearchAtNowNavBar from './SearchAtNowNavBar';
import SearchAtNowFooter from './SearchAtNowFooter';
import { useNavigate } from 'react-router-dom';
const stripHtmlTagsAndTruncate = (html, maxLength = 150) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.innerText || div.textContent || '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

function SearchAtNowBlog() {
    const [blogs, setBlogs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        axios.get('https://searchatnow.com/api/api/admin/colors')
            .then(response => {
                const primaryColor = response.data.primaryColor;
                document.documentElement.style.setProperty('--primary-color', primaryColor);
            })
            .catch(error => console.error(error));

        axios.get('https://searchatnow.com/api/api/admin/blog/all')
            .then(response => {
                setBlogs(response.data);
            })
            .catch(error => console.error('Error fetching blogs:', error));
    }, []);
    const handleReadMore = (id) => {
        navigate(`/${id}`);
    };
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredBlogs = blogs.filter(blog =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const backgroundImageStyle = {
        background: "url('src/images/about-us.svg') center center/cover no-repeat",
        /* other styles for your component go here */
    };
    return (
        <>
            <SearchAtNowNavBar />
            {/* <!-- breedcrumb section start  --> */}
            <section className="breedcrumb" style={backgroundImageStyle}>
                <div className="container">
                    <h2 className="breedcrumb__title text--heading-2">Blog List</h2>
                    <ul className="breedcrumb__page">
                        <li className="breedcrumb__page-item">
                            <a href="/" className="breedcrumb__page-link text--body-3">Home</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="/blog" className="breedcrumb__page-link text--body-3">Blog List</a>
                        </li>
                    </ul>
                </div>
            </section>
            {/* <!-- breedcrumb section end  --> */}


            {/* <!-- Blog-list section start  --> */}
            <section className="blog-list section pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="blog__sidebar">
                                <span className="toggle-icon">
                                    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.5 13.5H4.5C4.08579 13.5 3.75 13.8358 3.75 14.25V18C3.75 18.4142 4.08579 18.75 4.5 18.75H19.5C19.9142 18.75 20.25 18.4142 20.25 18V14.25C20.25 13.8358 19.9142 13.5 19.5 13.5Z"
                                            stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"
                                        />
                                        <path
                                            d="M19.5 5.25H4.5C4.08579 5.25 3.75 5.58579 3.75 6V9.75C3.75 10.1642 4.08579 10.5 4.5 10.5H19.5C19.9142 10.5 20.25 10.1642 20.25 9.75V6C20.25 5.58579 19.9142 5.25 19.5 5.25Z"
                                            stroke="currentColor" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                                <div className="blog__sidebar-wrapper">
                                    {/* <!-- Search --> */}
                                    <div className="blog__sidebar-search blog__sidebar-item">
                                        <h2 className="text--body-2-600 item-title">Search</h2>
                                        <div className="input-field">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                value={searchTerm}
                                                onChange={handleSearch}
                                            />
                                            <span className="icon textColor--primary-4">
                                                <i className="bi bi-search"></i>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <!-- Category --> */}
                                    <div className="blog__sidebar-category blog__sidebar-item">
                                        <h2 className="text--body-2-600 item-title">Top Category</h2>
                                        <div className="category">
                                            <div className="category-item">
                                                <a href="#">
                                                    <img src="src/img/properties - buy,rentandpg.svg" alt="category-img" />
                                                </a>
                                            </div>
                                            <div className="category-item">
                                                <a href="#">
                                                    <img src="src/img/beauty-parlours.svg" alt="category-img" />
                                                </a>
                                            </div>
                                            <div className="category-item">
                                                <a href="#">
                                                    <img src="src/img/hadoop-training.svg" alt="category-img" />
                                                </a>
                                            </div>
                                            <div className="category-item">
                                                <a href="#">
                                                    <img src="src/img/domestic-help-services.svg" alt="category-img" />
                                                </a>
                                            </div>
                                            <div className="category-item">
                                                <a href="#">
                                                    <img src="src/img/distance-education.svg" alt="category-img" />
                                                </a>
                                            </div>
                                            <div className="category-item">
                                                <a href="#">
                                                    <img src="src/img/entrance-exam-coaching.svg" alt="category-img" style={{ width: '100%' }} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="blog-list__wrapper">
                                {filteredBlogs.map(blog => {
                                    const firstImage = blog.images && blog.images.length > 0
                                        ? blog.images[0].replace(/\\/g, '/')
                                        : 'default-image-path.jpg'; // Fallback image path
                                    return (
                                        <div key={blog._id} className="blog-list__wrapper-item">
                                            <div className="blog-card">
                                                <div className="blog-card__left">
                                                    <div className="blog-card__img-wrapper">
                                                        <img src={`https://searchatnow.com/api/api/${firstImage}`} alt="blog-img" />
                                                    </div>
                                                </div>
                                                <div className="blog-card__right">
                                                    <a href="#" className="blog-card__blog-caption text--body-2">
                                                        {blog.title}
                                                    </a>
                                                    <p className="blog-card__description text--body-4">
                                                        {stripHtmlTagsAndTruncate(blog.description)}
                                                    </p>
                                                    <button onClick={() => handleReadMore(blog._id)} className="blog-card__readmore textColor--primary-4">
                                                        Read more
                                                        <span className="icon textColor--primary-4">
                                                            <i className="bi bi-arrow-right"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SearchAtNowFooter />
            {/* <!-- Blog-list section End   --> */}
        </>
    )
}
export default SearchAtNowBlog