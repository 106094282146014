import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SearchAtNowNavBar from './SearchAtNowNavBar';
import SearchAtNowFooter from './SearchAtNowFooter';
function SearchAtNowListingDeatails() {
    const { businessName } = useParams();
    const [ad, setAd] = useState(null);

    useEffect(() => {
        const fetchAdDetails = async () => {
            try {
                const response = await axios.get(`https://searchatnow.com/api/api/forms/ads/${encodeURIComponent(businessName)}`);
                setAd(response.data);
            } catch (error) {
                console.error('Error fetching ad details:', error);
            }
        };

        fetchAdDetails();
    }, [businessName]);

    if (!ad) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <SearchAtNowNavBar />
            {/* <!-- breedcrumb section start  --> */}
            {/* <section className="breedcrumb" >
            <div className="container">
                <h2 className="breedcrumb__title text--heading-2">Top Hotels in C G Road, Ahmedabad</h2>
                <ul className="breedcrumb__page">
                    <li className="breedcrumb__page-item">
                        <a href="index.html" className="breedcrumb__page-link text--body-3">Home</a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">Ad list</a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">Hotel KP </a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">/ </a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">Hotel Appolo</a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">other Hotels</a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">Other Hotel In </a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                    </li>
                    <li className="breedcrumb__page-item">
                        <a href="#" className="breedcrumb__page-link text--body-3">Hotel Honey Bunny</a>
                    </li>
                </ul>
            </div>
        </section> */}
            {/* <!-- breedcrumb section end  -->

        <!-- single ads section start  --> */}
            <section className="product-item section mt-3 ">
                <div class="container">
                    <div class="row ">
                        <a href=""> <img src="../src/img/aa2.jpg " alt="card-img" className='mt-3'></img></a>

                    </div>

                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="product-item__ads-info">
                                <h2 className="h4 text--heading-2 title mt-3 ">
                                    {ad.formData['Business-Name']}</h2>
                                <ul className="post-details">
                                    <li className="post-details__item">
                                        <span className="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12 12.75C13.6569 12.75 15 11.4069 15 9.75C15 8.09315 13.6569 6.75 12 6.75C10.3431 6.75 9 8.09315 9 9.75C9 11.4069 10.3431 12.75 12 12.75Z"
                                                    stroke="#767E94"
                                                    stroke-width="1.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                                <path
                                                    d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75V9.75Z"
                                                    stroke="#767E94"
                                                    stroke-width="1.6"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <p className="text--body-3">Sector -9 Ulwe, Navi Mumbai</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="product-item__gallery">
                                <div className="product-item__slider-content-top">
                                    <div id="carouselExample" class="carousel slide" data-bs-ride="true">
                                        <div class="carousel-inner">
                                            <div class="carousel-item b-high">
                                                <a href="">   <img src="../src/img/aa4.jpg" alt="card-img"></img></a>
                                            </div>
                                            <div class="carousel-item b-high">
                                                <a href="">   <img src="../src/img/r1.jpg" alt="card-img"></img></a>
                                            </div>
                                            <div class="carousel-item b-high">
                                                <a href="">   <img src="../src/img/r2.jpg" alt="card-img"></img></a>
                                            </div>
                                            <div class="carousel-item b-high active">
                                                <a href="">   <img src="../src/img/r1.jpg" alt="card-img"></img></a>
                                            </div>
                                        </div>
                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="product-item__description">
                                <h2 className="text--body-1-600">Descriptions</h2>
                                <p className="text--body-3">{ad.formData.Description}</p>
                            </div>
                            <div className="product-item__feature">
                                <h2 className="text--body-1-600">Feature</h2>
                                <ul className="feature">
                                    {ad.formData.Feature.split(' ').map((feature, index) => (
                                        <li key={index} className="feature-item">
                                            <span className="icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.25 6.75L9.75 17.2495L4.5 12" stroke="#01749d" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            <p className="text--body-3">{feature}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                        <div className="col-xl-4 mt-5">
                            <div className="product-item__sidebar mt-5">
                                <div className="product-item__sidebar-top mt-5">

                                    <div className=" product-item__sidebar-item">
                                        <div className="card-number">
                                            <div className="number number--hide text--body-2">
                                                <span className="icon">
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M19.9272 5C21.6225 5.45592 23.1682 6.34928 24.4095 7.59059C25.6508 8.8319 26.5441 10.3776 27.0001 12.0728"
                                                            stroke="#01749d"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M18.8916 8.86426C19.9087 9.13781 20.8362 9.67382 21.5809 10.4186C22.3257 11.1634 22.8617 12.0908 23.1353 13.108"
                                                            stroke="#01749d"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M11.5595 15.6015C12.5968 17.7222 14.3158 19.4334 16.4412 20.461C16.5967 20.5347 16.7687 20.5666 16.9403 20.5537C17.1119 20.5407 17.2771 20.4832 17.4198 20.387L20.5492 18.3002C20.6877 18.2079 20.8469 18.1516 21.0126 18.1364C21.1782 18.1212 21.3451 18.1475 21.498 18.213L27.3526 20.7222C27.5515 20.8067 27.7175 20.9535 27.8257 21.1405C27.9339 21.3276 27.9783 21.5447 27.9524 21.7593C27.7673 23.2073 27.0608 24.5382 25.9652 25.5028C24.8695 26.4674 23.4598 26.9996 22 26.9996C17.4913 26.9996 13.1673 25.2086 9.97919 22.0204C6.79107 18.8323 5 14.5083 5 9.99963C5.00008 8.53985 5.53224 7.13015 6.49685 6.03448C7.46146 4.93881 8.79237 4.23231 10.2404 4.04726C10.4549 4.0213 10.672 4.06576 10.8591 4.17394C11.0461 4.28212 11.193 4.44816 11.2775 4.64704L13.7888 10.5068C13.8537 10.6583 13.8802 10.8236 13.8658 10.9879C13.8514 11.1522 13.7967 11.3103 13.7064 11.4483L11.6268 14.6258C11.5322 14.7687 11.4762 14.9338 11.4644 15.1048C11.4526 15.2758 11.4854 15.447 11.5595 15.6015V15.6015Z"
                                                            stroke="#01749d"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                                7229820000
                                            </div>
                                            <div className="number number--show text--body-2">
                                                <span className="icon">
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M19.9272 5C21.6225 5.45592 23.1682 6.34928 24.4095 7.59059C25.6508 8.8319 26.5441 10.3776 27.0001 12.0728"
                                                            stroke="#01749d"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M18.8916 8.86426C19.9087 9.13781 20.8362 9.67382 21.5809 10.4186C22.3257 11.1634 22.8617 12.0908 23.1353 13.108"
                                                            stroke="#01749d"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                        <path
                                                            d="M11.5595 15.6015C12.5968 17.7222 14.3158 19.4334 16.4412 20.461C16.5967 20.5347 16.7687 20.5666 16.9403 20.5537C17.1119 20.5407 17.2771 20.4832 17.4198 20.387L20.5492 18.3002C20.6877 18.2079 20.8469 18.1516 21.0126 18.1364C21.1782 18.1212 21.3451 18.1475 21.498 18.213L27.3526 20.7222C27.5515 20.8067 27.7175 20.9535 27.8257 21.1405C27.9339 21.3276 27.9783 21.5447 27.9524 21.7593C27.7673 23.2073 27.0608 24.5382 25.9652 25.5028C24.8695 26.4674 23.4598 26.9996 22 26.9996C17.4913 26.9996 13.1673 25.2086 9.97919 22.0204C6.79107 18.8323 5 14.5083 5 9.99963C5.00008 8.53985 5.53224 7.13015 6.49685 6.03448C7.46146 4.93881 8.79237 4.23231 10.2404 4.04726C10.4549 4.0213 10.672 4.06576 10.8591 4.17394C11.0461 4.28212 11.193 4.44816 11.2775 4.64704L13.7888 10.5068C13.8537 10.6583 13.8802 10.8236 13.8658 10.9879C13.8514 11.1522 13.7967 11.3103 13.7064 11.4483L11.6268 14.6258C11.5322 14.7687 11.4762 14.9338 11.4644 15.1048C11.4526 15.2758 11.4854 15.447 11.5595 15.6015V15.6015Z"
                                                            stroke="#01749d"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </span>
                                                (808) 555-101
                                            </div>

                                            <span className="text--body-4 message">Click here to reveal phone number.</span>
                                        </div>
                                        <a href="message-board.html" className="btn w-100">
                                            <span className="icon--left">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M4.25895 16.5939C3.14076 14.7089 2.74916 12.4805 3.15768 10.3272C3.56621 8.1739 4.74675 6.2438 6.47764 4.89933C8.20853 3.55486 10.3707 2.8885 12.5581 3.02539C14.7455 3.16227 16.8078 4.09298 18.3575 5.64274C19.9073 7.19251 20.838 9.25472 20.9749 11.4421C21.1118 13.6296 20.4455 15.7917 19.101 17.5226C17.7565 19.2535 15.8264 20.4341 13.6732 20.8426C11.5199 21.2511 9.29149 20.8596 7.40649 19.7414L7.40651 19.7413L4.29808 20.6294C4.16947 20.6662 4.03338 20.6678 3.90391 20.6343C3.77443 20.6007 3.65628 20.5332 3.5617 20.4386C3.46713 20.344 3.39956 20.2259 3.36601 20.0964C3.33246 19.9669 3.33415 19.8308 3.37089 19.7022L4.25901 16.5938L4.25895 16.5939Z"
                                                        stroke="white"
                                                        stroke-width="1.6"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M12.325 12C12.325 12.1795 12.1795 12.325 12 12.325C11.8205 12.325 11.675 12.1795 11.675 12C11.675 11.8205 11.8205 11.675 12 11.675C12.1795 11.675 12.325 11.8205 12.325 12Z"
                                                        fill="white"
                                                        stroke="white"
                                                        stroke-width="1.6"
                                                    />
                                                    <path
                                                        d="M7.825 12C7.825 12.1795 7.67949 12.325 7.5 12.325C7.32051 12.325 7.175 12.1795 7.175 12C7.175 11.8205 7.32051 11.675 7.5 11.675C7.67949 11.675 7.825 11.8205 7.825 12Z"
                                                        fill="white"
                                                        stroke="white"
                                                        stroke-width="1.6"
                                                    />
                                                    <path
                                                        d="M16.825 12C16.825 12.1795 16.6795 12.325 16.5 12.325C16.3205 12.325 16.175 12.1795 16.175 12C16.175 11.8205 16.3205 11.675 16.5 11.675C16.6795 11.675 16.825 11.8205 16.825 12Z"
                                                        fill="white"
                                                        stroke="white"
                                                        stroke-width="1.6"
                                                    />
                                                </svg>
                                            </span>
                                            Send Message
                                        </a>
                                    </div>
                                    
                                    <div className="product-item__sidebar-item user-details">
                                        <div className="user">
                                            <div className="info">
                                                <span className="text--body-4">Add by:</span>
                                                <h2 className="text--body-3-600">Ramraj Yadav</h2>
                                            </div>
                                        </div>
                                        <ul className="contact">
                                            <li className="contact-item">
                                                <span className="icon">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21 5.25L12 13.5L3 5.25" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M3 5.25H21V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H3.75C3.55109 18.75 3.36032 18.671 3.21967 18.5303C3.07902 18.3897 3 18.1989 3 18V5.25Z" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10.3636 12L3.2312 18.538" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M20.7687 18.5381L13.6362 12" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                                <h6 className="text--body-3">{ad.formData.Mail}</h6>
                                            </li>
                                            <li className="contact-item">
                                                <span className="icon">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.25 21.75H18.75" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12 12.75C13.6569 12.75 15 11.4069 15 9.75C15 8.09315 13.6569 6.75 12 6.75C10.3431 6.75 9 8.09315 9 9.75C9 11.4069 10.3431 12.75 12 12.75Z" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75V9.75Z" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                                <h6 className="text--body-3">{ad.formData.Address}</h6>
                                            </li>
                                            <li className="contact-item">
                                                <span className="icon">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M4.64868 17.1935L5.74348 16.5326C5.85345 16.4662 5.94453 16.3727 6.00799 16.261C6.07144 16.1493 6.10514 16.0231 6.10586 15.8947L6.12488 12.5076C6.12567 12.3661 6.16649 12.2277 6.24261 12.1084L8.10285 9.19297C8.15783 9.1068 8.22985 9.03278 8.31448 8.97545C8.39911 8.91813 8.49456 8.8787 8.59498 8.8596C8.6954 8.84051 8.79866 8.84214 8.89843 8.8644C8.99819 8.88666 9.09236 8.92908 9.17513 8.98905L11.0179 10.3241C11.1739 10.4372 11.3676 10.4858 11.5585 10.46L14.5098 10.0603C14.6909 10.0358 14.8567 9.94602 14.9763 9.80789L17.0535 7.40781C17.1796 7.26211 17.2448 7.07346 17.2355 6.88099L17.126 4.60254" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M17.5376 19.0956L16.5315 18.0875C16.4374 17.9932 16.3198 17.9257 16.191 17.8919L14.1793 17.364C14.0007 17.3171 13.8457 17.2017 13.7403 17.0434L12.5397 15.2075C12.4296 15.038 12.2694 14.9032 12.0772 14.8222L10.7365 14.2463C10.6173 14.197 10.4873 14.175 10.3578 14.1822L7.25305 14.3784C7.13834 14.3847 7.02544 14.4154 6.92284 14.4689L5.00856 15.4929C4.79425 15.6054 4.57502 15.7974 4.50384 16.0694L4.35162 16.6377" stroke="#01749d" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                                <h6 className="text--body-3">{ad.formData.Website}</h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="">
                                        <div class="d-flex justify-content-evenly mt-2 mb-2 ">
                                            <div class="btn11">
                                                <a href={`https://wa.me/${ad.formData['WhatsApp-Number']}`} target="_blank" rel="noopener noreferrer">
                                                    <i class="bi bi-whatsapp"></i><span class="ms-1">WhatsApp Now</span>
                                                </a>
                                            </div>
                                            <div class="btn12 ms-2">
                                                <a href={`tel:${ad.formData['Contact-Number']}`} target="_blank" rel="noopener noreferrer">
                                                    <i class="bi bi-telephone"></i><span class="ms-1">Call Us</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- single ads section End  --> */}
            <SearchAtNowFooter />
        </div>



    )
}

export default SearchAtNowListingDeatails
