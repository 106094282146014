import React, { useState, useEffect } from 'react';

function FormDataDisplay() {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch('https://searchatnow.com/api/api/admin/categories');
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }
            const data = await response.json();
            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchSubcategories = async (category) => {
        try {
            const response = await fetch(`https://searchatnow.com/api/api/admin/subcategories?category=${category}`);
            if (!response.ok) {
                throw new Error('Failed to fetch subcategories');
            }
            const data = await response.json();
            setSubcategories(data);
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };

    const fetchFormData = async (category, subcategory) => {
        try {
            const response = await fetch(`https://searchatnow.com/api/api/forms/formdata?category=${category}&subcategory=${subcategory}`);
            if (!response.ok) {
                throw new Error('Failed to fetch form data');
            }
            const data = await response.json();
            setFormData(data);
        } catch (error) {
            console.error('Error fetching form data:', error);
        }
    };

    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setSelectedCategory(selectedCategory);
        fetchSubcategories(selectedCategory);
        setSelectedSubcategory(''); // Reset selected subcategory
    };

    const handleSubcategoryChange = (event) => {
        const selectedSubcategory = event.target.value;
        setSelectedSubcategory(selectedSubcategory);
        fetchFormData(selectedCategory, selectedSubcategory);
    };
    function renderField(field) {
        switch (field.type) {
            case 'text':
            case 'textarea':
            case 'number':
            case 'date':
                return <input type={field.type} id={field.name} name={field.name} required={field.required} className="form-control" />;
            case 'select':
                return (
                    <select id={field.name} name={field.name} required={field.required} className="form-select">
                        <option value="">Select {field.label}</option>
                        {field.values.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                );
            case 'file':
                return <input type="file" id={field.name} name={field.name} required={field.required} className="form-control" />;
            case 'radio':
                return (
                    <div>
                        {field.values.map((option, optionIndex) => (
                            <div key={optionIndex} className="form-check">
                                <input type={field.type} id={option.value} name={field.name} value={option.value} required={field.required} className="form-check-input" />
                                <label htmlFor={option.value} className="form-check-label">{option.label}</label>
                            </div>
                        ))}
                    </div>
                );
            case 'checkbox-group':
                return (
                    <div>
                        {field.values.map((option, optionIndex) => (
                            <div key={optionIndex} className="form-check">
                                <input type="checkbox" id={option.value} name={field.name} value={option.value} required={field.required} className="form-check-input" />
                                <label htmlFor={option.value} className="form-check-label">{option.label}</label>
                            </div>
                        ))}
                    </div>
                );
            case 'button':
                return <button type="button" id={field.name} className="btn btn-primary">{field.label}</button>;
            default:
                return null;
        }
    }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-6">
                    <label htmlFor="category" className="form-label">Select Category:</label>
                    <select id="category" value={selectedCategory} onChange={handleCategoryChange} className="form-select">
                        <option value="">Select</option>
                        {categories.map((category, index) => (
                            <option key={index} value={category.name}>{category.name}</option>
                        ))}
                    </select>
                </div>
                {selectedCategory && (
                    <div className="col-md-6">
                        <label htmlFor="subcategory" className="form-label">Select Subcategory:</label>
                        <select id="subcategory" value={selectedSubcategory} onChange={handleSubcategoryChange} className="form-select">
                            <option value="">Select</option>
                            {subcategories.map((subcategory, index) => (
                                <option key={index} value={subcategory.name}>{subcategory.name}</option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
            <div className="container mt-5">
                {formData.map((form, index) => (
                    <div key={index} className="card mb-5">
                        <div className="card-body">
                            {form.formData && form.formData.map((page, pageIndex) => (
                                <div key={pageIndex}>
                                    <div className="row">
                                        {page.map((field, fieldIndex) => (
                                            <div key={fieldIndex} className="col-md-6 mb-4">
                                                <div className="form-group">
                                                    <label htmlFor={field.name}>{field.label}</label>
                                                    {renderField(field)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FormDataDisplay;
