import React, { useEffect, useState } from 'react';
import axios from 'axios';

function SearchAtNowSignUp() {
    useEffect(() => {
        axios.get('https://searchatnow.com/api/api/admin/colors')
            .then(response => {
                const primaryColor = response.data.primaryColor;
                document.documentElement.style.setProperty('--primary-color', primaryColor);
            })
            .catch(error => console.error(error));
    }, [])
    const backgroundImageStyle = {
        background: "background: url('img/sign-up-in-bg.svg') center center/cover no-repeat;",
        /* other styles for your component go here */
    };
    const [formData, setFormData] = useState({
        fullName: '',
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        agreement: false,
        phoneNumber: '',
        city: ''
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Log the form data to check if it's being captured correctly
        console.log('Form Data:', formData);

        // Make a POST request to the registration endpoint with formData
        try {
            const response = await fetch('https://searchatnow.com/api/api/vendors/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            console.log('Registration response:', data);

            if (response.ok) {
                // Handle successful registration
                console.log('User registered successfully');
            } else {
                // Handle registration error
                console.error('Registration failed');
            }
        } catch (error) {
            console.error('Error during registration', error);
        }
    };
    return (
        <div>
            {/* <!-- breedcrumb section start  --> */}
            <section className="breedcrumb" style={backgroundImageStyle}>
                <div className="container">
                    <h2 className="breedcrumb__title text--heading-2">Sign Up</h2>
                    <ul className="breedcrumb__page">
                        <li className="breedcrumb__page-item">
                            <a href="index.html" className="breedcrumb__page-link text--body-3">Home</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">Account</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">Sign Up</a>
                        </li>
                    </ul>
                </div>
            </section>
            {/* <!-- breedcrumb section end  --> */}

            {/* <!-- registration section start   --> */}
            <section className="section registration">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-0">
                            <div className="registration__features">
                                <div className="registration__features-item">
                                    <span className="icon">
                                        <img src="src/img/chart.png.svg" alt="chart" />
                                    </span>
                                    <div className="registration__features-info">
                                        <h2 className="text--body-2-600">Manage Your Ads</h2>
                                        <p className="text--body-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            commodo vel ligula.</p>
                                    </div>
                                </div>
                                <div className="registration__features-item">
                                    <span className="icon">
                                        <img src="src/img/chart-circle.png.svg" alt="chart" />
                                    </span>
                                    <div className="registration__features-info">
                                        <h2 className="text--body-2-600">Chat with Anyone, Anytime</h2>
                                        <p className="text--body-3">Class aptent taciti sociosqu ad litora torquent per conubia
                                            nostra, per inceptos himenaeos.</p>
                                    </div>
                                </div>
                                <div className="registration__features-item">
                                    <span className="icon">
                                        <img src="src/img/chart.png.svg" alt="chart" />
                                    </span>
                                    <div className="registration__features-info">
                                        <h2 className="text--body-2-600">245,365 Verified User</h2>
                                        <p className="text--body-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            commodo vel ligula.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-0">
                            <div className="registration-form">
                                <h2 className="text-center text--heading-1 registration-form__title">Sign Up</h2>

                                <div className="registration-form__sign-btns">
                                    <a href="#" className="btn-sign " style={{ padding: "5px", important: "true" }}>
                                        <span className="icon">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.43242 12.0865L3.73625 14.6854L1.19176 14.7393C0.431328 13.3288 0 11.7151 0 10.0003C0 8.34203 0.403281 6.77828 1.11812 5.40137H1.11867L3.38398 5.81668L4.37633 8.0684C4.16863 8.67391 4.05543 9.32391 4.05543 10.0003C4.05551 10.7343 4.18848 11.4377 4.43242 12.0865Z"
                                                    fill="#FBBB00" />
                                                <path
                                                    d="M19.8253 8.13184C19.9401 8.73676 20 9.36148 20 9.99996C20 10.7159 19.9247 11.4143 19.7813 12.0879C19.2945 14.3802 18.0225 16.3818 16.2605 17.7983L16.2599 17.7978L13.4066 17.6522L13.0028 15.1313C14.172 14.4456 15.0858 13.3725 15.5671 12.0879H10.2198V8.13184H15.6451H19.8253Z"
                                                    fill="#518EF8" />
                                                <path
                                                    d="M16.2599 17.7975L16.2604 17.798C14.5467 19.1755 12.3698 19.9997 10 19.9997C6.19177 19.9997 2.8808 17.8711 1.19177 14.7387L4.43244 12.0859C5.27693 14.3398 7.45111 15.9442 10 15.9442C11.0956 15.9442 12.122 15.648 13.0027 15.131L16.2599 17.7975Z"
                                                    fill="#28B446" />
                                                <path
                                                    d="M16.383 2.30219L13.1434 4.95437C12.2319 4.38461 11.1544 4.05547 10 4.05547C7.39344 4.05547 5.17859 5.73348 4.37641 8.06812L1.11871 5.40109H1.11816C2.78246 2.1923 6.13519 0 10 0C12.4264 0 14.6511 0.864297 16.383 2.30219Z"
                                                    fill="#F14336" />
                                            </svg>
                                        </span>
                                        Sign in With Google
                                    </a>
                                    <a href="#" className="btn-sign " style={{ padding: "5px", important: "true" }}>

                                        <span className="icon py-1">
                                            <i className="bi bi-facebook textColor--primary-2  fs-6"></i>
                                            Sign in With Facebook
                                        </span>

                                    </a>
                                </div>

                                <h2 className="registration-form__alternative-title text--body-3">or Sign Up With Email</h2>

                                <div className="registration-form__wrapper">
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-field__group">
                                            <div className="input-field">
                                                <input type="text" placeholder="Full Name" name="fullName" value={formData.fullName}
                                                    onChange={handleChange} />
                                            </div>
                                            <div className="input-field">
                                                <input type="text" placeholder="User Name" name='userName' value={formData.userName} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="input-field__group">
                                            <div className="input-field">
                                                <input type="text" placeholder="City" name="city" value={formData.city}
                                                    onChange={handleChange} />
                                            </div>
                                            <div className="input-field">
                                                <input type="text" placeholder="Phone Number" name='phoneNumber' value={formData.phoneNumber} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="input-field">
                                            <input type="text" placeholder="Username or email address" name='email' value={formData.email} onChange={handleChange} />
                                            <span className="icon icon--success">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="20" height="20" rx="10" fill="#27C200" />
                                                    <path d="M14 7L8.5 12.5L6 10" stroke="white" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="icon icon--alart">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.29 3.86L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7239C2.83871 20.901 3.18082 20.9962 3.53002 21H20.47C20.8192 20.9962 21.1613 20.901 21.4623 20.7239C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.86C13.5318 3.56611 13.2807 3.32312 12.9812 3.15448C12.6817 2.98585 12.3438 2.89725 12 2.89725C11.6563 2.89725 11.3184 2.98585 11.0188 3.15448C10.7193 3.32312 10.4683 3.56611 10.29 3.86Z"
                                                        fill="#FF4F4F" />
                                                    <path d="M12 9V13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M12 17H12.01" stroke="white" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="input-field">
                                            <input type="password" placeholder="Password" id="password" name='password' value={formData.password} onChange={handleChange} />
                                            <span className="icon icon--eye" onclick="showPassword('password',this)">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                                                        stroke="#191F33" stroke-width="1.3" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path
                                                        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                                        stroke="#191F33" stroke-width="1.3" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="icon icon--success">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="20" height="20" rx="10" fill="#27C200" />
                                                    <path d="M14 7L8.5 12.5L6 10" stroke="white" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="icon icon--alart">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.29 3.86L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7239C2.83871 20.901 3.18082 20.9962 3.53002 21H20.47C20.8192 20.9962 21.1613 20.901 21.4623 20.7239C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.86C13.5318 3.56611 13.2807 3.32312 12.9812 3.15448C12.6817 2.98585 12.3438 2.89725 12 2.89725C11.6563 2.89725 11.3184 2.98585 11.0188 3.15448C10.7193 3.32312 10.4683 3.56611 10.29 3.86Z"
                                                        fill="#FF4F4F" />
                                                    <path d="M12 9V13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M12 17H12.01" stroke="white" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="input-field">
                                            <input type="password" placeholder="Confirm Password" id="cpassword" name="confirmPassword"
                                                checked={formData.confirmPassword}
                                                onChange={handleChange} />
                                            <span className="icon icon--eye" onclick="showPassword('cpassword',this)">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z"
                                                        stroke="#191F33" stroke-width="1.3" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path
                                                        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                                        stroke="#191F33" stroke-width="1.3" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="icon icon--success">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="20" height="20" rx="10" fill="#27C200" />
                                                    <path d="M14 7L8.5 12.5L6 10" stroke="white" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="icon icon--alart">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.29 3.86L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7239C2.83871 20.901 3.18082 20.9962 3.53002 21H20.47C20.8192 20.9962 21.1613 20.901 21.4623 20.7239C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.86C13.5318 3.56611 13.2807 3.32312 12.9812 3.15448C12.6817 2.98585 12.3438 2.89725 12 2.89725C11.6563 2.89725 11.3184 2.98585 11.0188 3.15448C10.7193 3.32312 10.4683 3.56611 10.29 3.86Z"
                                                        fill="#FF4F4F" />
                                                    <path d="M12 9V13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M12 17H12.01" stroke="white" stroke-width="1.5"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </div>

                                        <div className="registration-form__option">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="checkme" name="agreement"
                                                    checked={formData.agreement}
                                                    onChange={handleChange} />
                                                <label className="form-check-label " for="checkme">
                                                    I've read and agree with your <a href="#" className="textColor--primary-3">Privacy Policy</a> and
                                                    <a href="#" className="textColor--primary-3">
                                                        Terms & Conditions
                                                    </a>
                                                </label>
                                            </div>
                                        </div>

                                        <button className="btn btn--lg w-100 registration-form__btns  bgcolor--primary-4" type="submit">
                                            Sign Up
                                            <span className="icon--right">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.75 12H20.25" stroke="white" stroke-width="1.6"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="white" stroke-width="1.6"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </button>

                                        <p className="text--body-3 registration-form__redirect ">Already Have an Account ? <a
                                            href="signin.html" className="textColor--primary-3">Sign In</a></p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- registration section  end    --> */}
        </div>
    )
}

export default SearchAtNowSignUp
