import React from 'react'

function SearchAtNowNavBar() {
    return (
        <div>
            <header className="header header--one " id="sticky-menu">
                <div className="container navigation-bar">
                    <div className="d-flex align-items-center ">
                        <button className="toggle-icon  ">
                            <span className="toggle-icon__bar"></span>
                            <span className="toggle-icon__bar"></span>
                            <span className="toggle-icon__bar"></span>
                        </button>
                        <a href="/" className="navigation-bar__logo">
                            <img src="../../../src/img/logo01.png" alt="brand-logo" className="logo-white text-white" style={{ width: "30%" }} />
                            <img src="../../../src/img/logo01.png" alt="brand-logo" className="logo-dark" style={{ width: "30%" }} />
                        </a>
                    </div>
                    <ul className="menu">
                        <li className="menu__item">
                            <a href="/" className="menu__link">Home</a>
                        </li>
                        <li className="menu__item">
                            <a href="/about" className="menu__link">About</a>
                        </li>
                        <li className="menu__item">
                            <a href="/listing" className="menu__link">Listing</a>
                        </li>
                        <li className="menu__item">
                            <a href="/blog" className="menu__link">Blog</a>
                        </li>
                        <li className="menu__item">
                            <a href="/contact" className="menu__link">Contact</a>
                        </li>
                    </ul>
                    <div className="login">
                        <div className="loginbt d-flex ">
                            <span><a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Login</a></span> <span className='text-white ms-2'> / </span>    <span className='ms-2'><a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop"> Register</a></span>
                        </div>
                    </div>
                    <div className="navigation-bar__buttons">
                        {/* <a href="signin.html" className="btn btn--bg">Sign in</a> */}
                        <a href="/dashboard" className="btn">
                            <span className="icon--left">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" stroke-width="1.6" stroke-miterlimit="10"></path>
                                    <path d="M8.25 12H15.75" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M12 8.25V15.75" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </span>
                            Add Listing
                        </a>

                    </div>

                    <div className="menu--sm mobile-menu">
                        <div className="mobile-menu__header">
                            <div className="mobile-menu__brand">
                                <a href="index.html">
                                    <img src="../src/images/svg/logo-dark.svg" alt="logo" />
                                </a>
                                <div className="close">
                                    <span>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 5.08325L15.6066 15.6899" stroke="#191F33" stroke-width="1.9375" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M4.99999 15.9167L15.6066 5.31015" stroke="#191F33" stroke-width="1.9375" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="mobile-menu__search">
                                <form action="#">
                                    <div className="input-field">
                                        <input type="text" placeholder="Ads, Title, keyword..." className="" />
                                        <button className="icon icon-search">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#00AAFF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M21.0004 21.0004L16.6504 16.6504" stroke="#00AAFF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

            </header>

            {/* modal */}
            <div class="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">  <a href="/" className="navigation-bar__logo">
                                <div className='d-flex justify-content-center align-self-center '>
                                    <img src="../src/img/logo01.png" alt="brand-logo" className="logo-white text-white" style={{ width: "29%" }} />


                                </div>
                            </a>
                            </h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12">
                                <h2 class="text--heading-1 section__title fw-bold">Login for experience</h2>

                                <div class="loginfo text-center">
                                    <input type="name" placeholder="user name" />
                                    <div className='mt-2'>
                                        <input type="" placeholder="password" />
                                    </div>

                                    <div className='lobt text-center mt-4'><a href="#"> Login in </a></div>
                                </div>
                                {/* <div class="d-flex justify-content-evenly mt-4">
             <div className='bt1'> <i class="bi bi-google"></i> <a href="">Google</a></div>
             <div className='bt1'> <i class="bi bi-facebook"></i>  <a href="">Facebook</a></div>
             </div> */}
                            </div>


                            {/*  */}

                        </div>

                    </div>
                </div>
            </div>

            <nav class="navbar bg-body-tertiary fixed-top">
                <div class="container headertwo">
                    <div class="row">
                        <div class="col-8">
                            <a class="navbar-brand" href="#"> <img src="../src/img/logo01.png" alt="brand-logo" className="logo-dark" style={{ width: "30%" }} /></a>


                        </div>
                        <div class="col-4 d-flex">
                            <a class="navbar-brand" href="#">  <i class="text-white ms-2 bi bi-plus-circle"></i></a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"><i class="bi bi-blockquote-left"></i></span>
                            </button>
                            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                                <div class="offcanvas-header">

                                    <a class="navbar-brand" href="#"> <img src="../src/img/logo01.png" alt="brand-logo" className="logo-dark" style={{ width: "30%" }} /></a>

                                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div class="offcanvas-body">
                                    <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                                        <li class="nav-item">
                                            <a class="nav-link active" aria-current="page" href="/">Home</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="/about">About</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="/listing">Listing</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="/blog">Blog</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="/contact">Contact</a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

        </div>



    )
}

export default SearchAtNowNavBar
