import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SearchAtNowNavBar from './SearchAtNowNavBar';
import SearchAtNowFooter from './SearchAtNowFooter';
import { Helmet } from 'react-helmet';
function SearchAtNowContact() {
    const [meta, setMeta] = useState({});
    const pathname = window.location.pathname;
    const pageName = pathname.replace('/', '');
    const backgroundImageStyle = {
        background: "url('img/contact.svg') center center/cover no-repeat; height: 200px !important;",
        /* other styles for your component go here */
    };
    useEffect(() => {
        axios.get('https://searchatnow.com/api/api/admin/colors')
            .then(response => {
                const primaryColor = response.data.primaryColor;
                document.documentElement.style.setProperty('--primary-color', primaryColor);
            })
            .catch(error => console.error(error));
    }, [])

    useEffect(() => {
        const fetchMeta = async () => {
            try {
                const response = await axios.get(`https://searchatnow.com/api/api/admin/meta/${pageName}`);
                setMeta(response.data);
            } catch (error) {
                console.error('Error fetching meta information:', error);
            }
        };

        fetchMeta();
    }, [pageName]);

    return (
        <div>
            <Helmet>
                <title>{meta.metaTitle}</title>
                <meta name="description" content={meta.metaDescription} />
            </Helmet>
            <SearchAtNowNavBar />
            {/* <!-- breedcrumb section start  --> */}
            <section className="breedcrumb"
                style={backgroundImageStyle}>
                <div className="container">
                    <h2 className="breedcrumb__title text--heading-2">contact</h2>
                    <ul className="breedcrumb__page">
                        <li className="breedcrumb__page-item">
                            <a href="/" className="breedcrumb__page-link text--body-3">Home</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="/contact" className="breedcrumb__page-link text--body-3">contact</a>
                        </li>
                    </ul>
                </div>
            </section>
            {/* <!-- breedcrumb section end  --> */}

            {/* <!-- Contact section start  --> */}
            <section className="section contact">
                <div className="container">
                    <div className="contact-form">
                        <div className="contact-form__left">
                            <h2 className="contact-form__title text--heading-2">Contact info</h2>

                            <ul className="contact__details">
                                <li className="contact__details-item">
                                    <span className="icon textColor--primary-4">
                                        <i className="bi bi-telephone-fill"></i>
                                    </span>
                                    <p className="text--body-3">(141) 444-5762</p>
                                </li>
                                <li className="contact__details-item">
                                    <span className="icon textColor--primary-4">
                                        <i className="bi bi-envelope-fill"></i>
                                    </span>

                                    <p className="text--body-3">
                                        support@cwp.com

                                        <br />
                                        career@cwp.com
                                    </p>
                                </li>
                                <li className="contact__details-item">
                                    <span className="icon textColor--primary-4">
                                        <i className="bi bi-geo-alt-fill"></i>
                                    </span>

                                    <p className="text--body-3">
                                        1122 gandhipath. jaipur, raj. 44560
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="contact-form__right">
                            <h2 className="contact-form__title text--heading-2 ">Send Message</h2>

                            <form action="#">
                                <div className="input-field__group">
                                    <div className="input-field">
                                        <input type="text" placeholder="Full Name" />
                                    </div>
                                    <div className="input-field">
                                        <input type="email" placeholder="Email Address" />
                                    </div>
                                </div>
                                <div className="input-field">
                                    <input type="text" placeholder="mobilenumber" />
                                    <span className="icon icon--success">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect width="20" height="20" rx="10" fill="#27C200" />
                                            <path d="M14 7L8.5 12.5L6 10" stroke="white" stroke-width="1.5"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <span className="icon icon--alart">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.29 3.86L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7239C2.83871 20.901 3.18082 20.9962 3.53002 21H20.47C20.8192 20.9962 21.1613 20.901 21.4623 20.7239C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.86C13.5318 3.56611 13.2807 3.32312 12.9812 3.15448C12.6817 2.98585 12.3438 2.89725 12 2.89725C11.6563 2.89725 11.3184 2.98585 11.0188 3.15448C10.7193 3.32312 10.4683 3.56611 10.29 3.86Z"
                                                fill="#FF4F4F" />
                                            <path d="M12 9V13" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path d="M12 17H12.01" stroke="white" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                </div>
                                <div className="input-field--textarea">
                                    <textarea placeholder="Subject"></textarea>
                                </div>

                                <button className="btn bgcolor--primary-4 ">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Contact section end --> */}
            <div id="map"></div>
            <SearchAtNowFooter />
        </div>
    )
}

export default SearchAtNowContact
