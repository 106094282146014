import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SearchAtNowNavBar from './SearchAtNowNavBar';
import SearchAtNowFooter from './SearchAtNowFooter';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const stripHtmlTagsAndTruncate = (html, maxLength = 150) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const text = div.innerText || div.textContent || '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

function SearchAtNowHome() {
    const [city, setCity] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [category, setCategory] = useState('');
    const [subcategories, setSubcategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const navigate = useNavigate();
    const getCityFromCoordinates = async (latitude, longitude) => {
        try {
            console.log("Fetching city for coordinates:", latitude, longitude);
            const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    latlng: `${latitude},${longitude}`,
                    key: 'AIzaSyAcyypi3Pv_D-h35Q_jx-37sFRChceGIaA'
                }
            });

            console.log("Google Maps API response:", response.data);

            const result = response.data.results[0];
            const addressComponents = result.address_components;
            const neighborhood = addressComponents.find(component => component.types.includes('neighborhood'))?.long_name;
            const sublocality = addressComponents.find(component => component.types.includes('sublocality_level_2'))?.long_name;
            const locality = addressComponents.find(component => component.types.includes('locality'))?.long_name;
            const route = addressComponents.find(component => component.types.includes('route'))?.long_name;
            const administrativeArea = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name;

            console.log("Extracted location details:", { neighborhood, sublocality, locality, route, administrativeArea });

            const formattedAddress = [
                neighborhood,
                sublocality,
                locality
            ].filter(Boolean).join(', ');

            setCity(formattedAddress);
        } catch (error) {
            console.error("Error getting city from coordinates:", error);
            setCity("Unknown Location");
        }
    };

    const detailedArea = city.split(', ')[0] || '';
    const mainCity = city.split(', ')[1] || city;

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                console.log("User's position:", position);
                getCityFromCoordinates(latitude, longitude);
            }, (error) => {
                console.error("Error getting user location:", error);
                setCity("Location Access Denied");
            });
        } else {
            console.error("Geolocation is not supported by this browser.");
            setCity("Geolocation Not Supported");
        }
    };

    useEffect(() => {
        getUserLocation();
    }, []);

    useEffect(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
            const autocomplete = new window.google.maps.places.Autocomplete(
                document.getElementById('location-input'),
                { types: ['geocode'] }
            );

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const addressComponents = place.address_components;
                const neighborhood = addressComponents.find(component => component.types.includes('neighborhood'))?.long_name;
                const sublocality = addressComponents.find(component => component.types.includes('sublocality_level_1'))?.long_name;
                const locality = addressComponents.find(component => component.types.includes('locality'))?.long_name;
                const route = addressComponents.find(component => component.types.includes('route'))?.long_name;
                const administrativeArea = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name;

                const formattedAddress = [
                    neighborhood,
                    sublocality,
                    route,
                    locality,
                    administrativeArea
                ].filter(Boolean).join(', ');

                setCity(formattedAddress);
            });
        } else {
            console.error("Google Maps JavaScript API library is not loaded.");
        }
    }, []);
    useEffect(() => {
        axios.get('https://searchatnow.com/api/api/admin/colors')
            .then(response => {
                const primaryColor = response.data.primaryColor;
                document.documentElement.style.setProperty('--primary-color', primaryColor);
            })
            .catch(error => console.error(error));

        axios.get('https://searchatnow.com/api/api/admin/categories')
            .then(response => {
                const activeCategories = response.data.filter(category => category.status === 'active');
                setCategories(activeCategories);
            })
            .catch(error => console.error(error));

        // Fetch blogs
        axios.get('https://searchatnow.com/api/api/admin/blog/all')
            .then(response => {
                setBlogs(response.data);
            })
            .catch(error => console.error(error));
    }, []);
    useEffect(() => {
        axios.get('https://searchatnow.com/api/api/admin/categories')
            .then(response => {
                const activeCategories = response.data.filter(category => category.status === 'active');
                setCategories(activeCategories);
            })
            .catch(error => console.error(error));

        axios.get('https://searchatnow.com/api/api/admin/subcategories')
            .then(response => {
                const activeSubcategories = response.data.filter(subcategory => subcategory.status === 'active');
                setSubcategories(activeSubcategories);
            })
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        const combinedSuggestions = [
            ...categories.map(category => category.name),
            ...subcategories.map(subcategory => subcategory.name)
        ];
        setSuggestions(combinedSuggestions);
    }, [categories, subcategories]);
    
    // Handle input change for search term with suggestions
    const handleSearchTermChange = (e) => {
        const inputValue = e.target.value;
        setSearchTerm(inputValue);
    
        // Filter suggestions based on user input
        const filtered = suggestions.filter(suggestion =>
            suggestion.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredSuggestions(filtered);
    };
    
    // Handle suggestion click
    const handleSuggestionClick = (suggestion) => {
        setSearchTerm(suggestion);
        setFilteredSuggestions([]); // Clear suggestions after selection
    
        // Automatically find the category associated with the selected subcategory
        const selectedSubcategory = subcategories.find(sub => sub.name === suggestion);
        if (selectedSubcategory) {
            const selectedCategory = categories.find(cat => cat.id === selectedSubcategory.categoryId);
            if (selectedCategory) {
                setCategory(selectedCategory.name);
            }
        }
    };
    
    // Handle form submission
    const formatForURL = (string) => {
        return string.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };
    
    // Handle form submission
    const handleFormSubmit = (e) => {
        e.preventDefault();
    
        const detailedArea = city.split(', ')[0] || '';
        const mainCity = city.split(', ')[1] || city;
    
        // Construct the URL with cleaned and formatted values
        const url = `/${formatForURL(mainCity)}/${formatForURL(category)}/${formatForURL(detailedArea)}/${formatForURL(searchTerm)}`;
        
        // Navigate to the constructed URL
        navigate(url);
    };
    
    return (
        <div>
            <SearchAtNowNavBar />
            <section className="banner banner--one section"
                style={{ background: `url('./src/img/div.bg-slide.svg') center center/cover no-repeat` }}>
                <div className="container">
                    <h2 className="text--display-2 text-center banner__title animate__animated animate__bounceInDown" style={{ fontSize: '40px' }}>
                        Search across <span id="spin"> Businesses  Services
                        </span>
                    </h2>

                    <div className="search">
        <form onSubmit={handleFormSubmit}>
            <div className="search__content">
                <div className="search__content-item">
                    <div className="input-field">
                        <input
                            type="text"
                            id="location-input"
                            placeholder='Enter City'
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                        <span className="icon icon--left textColor--primary-3">
                            <i className="bi bi-geo-alt"></i>
                        </span>
                    </div>
                </div>

                <div className="search__content-item" style={{ width: '44%' }}>
                    <div className="input-field">
                        <input
                            type="text"
                            placeholder='What Are You Looking For ...'
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                        />
                        <span className="icon icon--left textColor--primary-3">
                            <i className="bi bi-search"></i>
                        </span>
                        {searchTerm && filteredSuggestions.length > 0 && (
                            <ul className="autocomplete-suggestions">
                                {filteredSuggestions.map((suggestion, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleSuggestionClick(suggestion)}
                                    >
                                        {suggestion}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                <div className="search__content-item">
                    <button className="ms-3 btn btn--lg bgcolor--primary-4" type="submit">
                        <span className="icon--left">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                                    stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M21 21L16.65 16.65" stroke="white" strokeWidth="1.6"
                                    strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                        Search
                    </button>
                </div>
            </div>
        </form>
    </div>


                    {/*  */}
                    <div className='newsearch'>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-11'>
                                <div className="d-flex input9i">
                                    <div className="ms-2">
                                        <i className="bi bi-geo-alt"></i>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Enter City"
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-11'>
                                <div className="d-flex input9i">
                                    <div className="ms-2">
                                        <i className="bi bi-search"></i>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="What Are You Looking For..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='sebt text-center'>
                            <a href="#">
                                <span className='fw-bold'> Search </span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="container-fluid">
                    <div class="row ">
                        <a href="">   <img src="./src/img/add2.jpg " alt="card-img" className='mt-3'></img></a>

                    </div>

                </div>
            </section>
            <section className='imgtwo'>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="cdimg">
                                <div id="carouselExample" class="carousel slide" data-bs-ride="true">
                                    <div class="carousel-inner">
                                        <div class="carousel-item b-high">
                                            <a href="">   <img src="./src/img/r1.jpg" alt="card-img"></img></a>

                                        </div>
                                        <div class="carousel-item b-high">
                                            <a href="">   <img src="./src/img/r2.jpg" alt="card-img"></img></a>
                                        </div>
                                        <div class="carousel-item b-high">
                                            <a href="">   <img src="./src/img/aa4.jpg" alt="card-img"></img></a>
                                        </div>
                                        <div class="carousel-item b-high active">
                                            <a href="">   <img src="./src/img/r3.jpg" alt="card-img"></img></a>
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="cdimg">
                                <div id="carouselExampleRide" class="carousel slide" data-bs-ride="true">
                                    <div class="carousel-inner">
                                        <div class="carousel-item">
                                            <a href="">   <img src="./src/img/r6.jpg" alt="card-img"></img></a>
                                        </div>
                                        <div class="carousel-item">
                                            <a href="">   <img src="./src/img/r2.jpg" alt="card-img"></img></a>
                                        </div>
                                        <div class="carousel-item active">
                                            <a href="">   <img src="./src/img/r1.jpg" alt="card-img"></img></a>
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* <section class="number">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-3 col-6 text-center">

                            <h3 class="num1">345</h3>
                            <div class="bra"></div>
                            <h6> All Services</h6>
                        </div>

                        <div class="col-md-3 col-6 text-center">

                            <h3 class="num2">178</h3>
                            <div class="bra"></div>
                            <h6>Consultants </h6>
                        </div>

                        <div class="col-md-3 col-6 text-center">

                            <h3 class="num3">30</h3>
                            <div class="bra"></div>
                            <h6>Million</h6>
                        </div>

                        <div class="col-md-3 col-6 text-center">

                            <h3 class="num4">523</h3>
                            <div class="bra"></div>
                            <h6>Events</h6>
                        </div>

                    </div>
                </div>
            </section> */}
            <div class="button1 text-center">
                <div class="p1 verticalButton"><a href="#">   Advertise </a></div>
                <div class="p2 verticalButton1 ms-1"><a href="#"> Free Listing </a></div>

            </div>
            <section className="py-3">
                <div className="container-fluid">
                    <div className="row">
                        {categories.map((cat) => (
                            <div key={cat._id} className="col-md-1 col-6 text-center">
                                <div className="mainu1">
                                    <div className="mainu1-img">
                                        <Link to={`/${encodeURIComponent(mainCity.toLowerCase())}/${encodeURIComponent(cat.name.toLowerCase())}${detailedArea ? `/${detailedArea.replace(/\s+/g, '-').toLowerCase()}` : ''}`}>
                                            <img src={`https://searchatnow.com/api/api/uploads/${cat.iconImage}`} alt="category-img" />
                                        </Link>
                                    </div>
                                </div>
                                <Link to={`/${encodeURIComponent(mainCity.toLowerCase())}/${encodeURIComponent(cat.name.toLowerCase())}${detailedArea ? `/${detailedArea.replace(/\s+/g, '-').toLowerCase()}` : ''}`}>
                                    <p>{cat.name}</p>
                                </Link>
                            </div>
                        ))}


                        {/* Add other static categories or elements here */}
                        <div className="col-md-1 col-6 text-center">
                            <h5 type="" className="" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <div className="mainu1">
                                    <div className="mainu1-img">
                                        <img src="./src/img/a20.jpg" alt="card-img" />
                                    </div>
                                </div>
                            </h5>
                            <p>All Categories</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section feature-ads">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-xl-6">
                            <div className="img-hov ">
                                <h6 className='mb-2'>Beauty & Spa</h6>
                                <div className="cards  cards--highlight "
                                    style={{ height: '100%', borderRadius: '0px 0px 0px 0px', "!important": 'true' }}>
                                    <a href="ad-details.html" className="cards__img-wrapper">
                                        <img src="./src/img/aa4.jpg" alt="card-img" />
                                    </a>
                                </div>
                            </div>


                        </div>
                        <div className="col-xl-6">
                            <div className="img-hov">
                                <h6 className='mb-2'>Repairs & Services</h6>
                                <div className="cards  cards--highlight"
                                    style={{ height: '100%', borderRadius: '0px 0px 0px 0px', "!important": 'true' }}>
                                    <a href="ad-details.html" className="cards__img-wrapper">
                                        <img src="./src/img/r6.jpg" alt="card-img" />
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="section recent-post bgcolor--gray-10">
                <div className="container">
                    <div className="row">
                        {categories.map(category => (
                            <div key={category._id} className="col-xl-3 col-md-6">
                                <div className="cards cards--one">
                                    <a href="#" className="cards__img-wrapper">
                                        <img src={`https://searchatnow.com/api/api/uploads/${category.image}`} alt="card-img" className="img-fluid" />
                                    </a>
                                    <div className="cards__info">
                                        <div className="cards__info-top">
                                            <a href="#" className="text--body-3-600 cards__caption-title">
                                                {category.name}
                                            </a>
                                        </div>
                                        <div className="cards__info-bottom ">
                                            <a href="#" className="btn bgcolor--primary-4 ">View more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
            </section> */}
            <section className="section recent-post bgcolor--gray-10">
                <div className="container-fluid">
                    <h2 className="text--heading-1 section__title" style={{ marginBottom: '10px !important' }}>
                        Related Articles
                    </h2>
                    <div className="row">
                        {blogs.map(blog => {
                            const firstImage = blog.images && blog.images.length > 0
                                ? blog.images[0].replace(/\\/g, '/')
                                : 'default-image-path.jpg'; // Fallback image path
                            return (
                                <div key={blog._id} className="col-xl-3 col-md-6">
                                    <div className="cards cards--one">
                                        <a href="#" className="cards__img-wrapper">
                                            <img src={`https://searchatnow.com/api/api/${firstImage}`} alt="card-img" className="img-fluid" />
                                        </a>
                                        <div className="cards__info">
                                            <div className="cards__info-top">

                                                <a href="#" className="text--body-3-600 cards__caption-title">
                                                    {blog.title}
                                                </a>
                                                <span className="text--body-5 textColor--gray-3" style={{ lineHeight: '0px' }}>
                                                    {stripHtmlTagsAndTruncate(blog.description)}
                                                </span>
                                            </div>
                                            <div className="cards__info-bottom">
                                                <h6 className="cards__location text--body-4 ">
                                                    <Link to={`/${blog._id}`} className="m-auto border border-primary p-2 px-5 ms-4 textColor--primary-3">
                                                        Read more
                                                    </Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            {/* <SearchAtNowFooter/> */}
            <SearchAtNowFooter />
        </div >

    )
}

export default SearchAtNowHome
