import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import SearchAtNowNavBar from './SearchAtNowNavBar';
import SearchAtNowFooter from './SearchAtNowFooter';

function SearchAtNowListing() {
    const { city, categoryName, subcategoryName, detailedArea } = useParams();
    const [ads, setAds] = useState([]);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                const decodedSubcategoryName = decodeURIComponent(subcategoryName);
                const response = await axios.get(`https://searchatnow.com/api/api/forms/ads/bySubcategory/${encodeURIComponent(decodedSubcategoryName)}`);
                setAds(response.data);
            } catch (error) {
                console.error('Error fetching ads:', error);
            }
        };

        fetchAds();
    }, [subcategoryName]);

    useEffect(() => {
        axios.get('https://searchatnow.com/api/api/admin/colors')
            .then(response => {
                const primaryColor = response.data.primaryColor;
                document.documentElement.style.setProperty('--primary-color', primaryColor);
            })
            .catch(error => console.error(error));
    }, []);

    const toUrlFormat = (text) => {
        return text.toLowerCase().replace(/\s+/g, '-');
    };

    const backgroundImageStyle = {
        background: "background: url('../../../src/img/list-bg.svg') center center/cover no-repeat;",
    };

    return (
        <div>
            <SearchAtNowNavBar />
            <section className="breedcrumb" style={backgroundImageStyle}>
                <div className="container">
                    <h2 className="breedcrumb__title text--heading-2">List</h2>
                    <ul className="breedcrumb__page">
                        <li className="breedcrumb__page-item">
                            <a href="/" className="breedcrumb__page-link text--body-3">Home</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href={`/listing/${encodeURIComponent(subcategoryName)}`} className="breedcrumb__page-link text--body-3">list</a>
                        </li>
                    </ul>
                </div>
            </section>
            <div className="container-fluid">
                <div className="row">
                    <a href=""> <img src="../../../src/img/aa3.jpg" alt="card-img" className='mt-3'></img></a>
                </div>
            </div>
            <div className="mobile-search-filed">
                <div className="container-fluid">
                    <div className="search-field-wrap">
                        <div className="input-field">
                            <input type="text" placeholder="Search for anything" />
                            <span className="icon icon--left textColor--primary-3">
                                <i className="bi bi-search"></i>
                            </span>
                        </div>
                        <span className="toggle-bar">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 11.25L12 20.25" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 3.75L12 8.25" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18.75 18.75L18.7501 20.25" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M18.7501 3.75L18.75 15.75" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M21 15.75H16.5" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.25007 15.75L5.25 20.25" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.25 3.75L5.25007 12.75" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M3 12.75H7.5" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M14.25 8.25H9.75" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
            <div className="offcanvas-overlay"></div>

            <div className="button1 text-center">
                <div className="p1 verticalButton"><a href="#"> Advertise </a></div>
                <div className="p2 verticalButton1 ms-1"><a href="#"> Free Listing </a></div>
            </div>
            <section className='new-list'>
                <div className="container-fluid">
                    <h2 className='h4 py-2'>Top Ads</h2>
                    <div className="row">
                        <div className="col-md-9">
                            {ads.map((ad, index) => (
                                <div key={index} className="newlis">
                                    <div className="newlis1">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="carousel slide" data-bs-ride="carousel">
                                                    <div className="carousel-inner">
                                                        <div className="carousel-item active">
                                                            <img src="../../../src/img/home.jpg" className="d-block w-100" alt="Ad" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <div className="new-lis2">
                                                    <h4>{ad.formData['Business-Name']}</h4>
                                                    <p><i className="bi bi-geo-alt"></i> {ad.formData.Address}</p>
                                                    <p>{ad.formData['Contact-Number']}</p>
                                                    <div className='d-flex mt-3'>
                                                        <div className="btn11">
                                                            <a href={`https://wa.me/${ad.formData['WhatsApp-Number']}`}>
                                                                <i className="bi bi-whatsapp"></i>
                                                                <span className='ms-1'>WhatsApp</span>
                                                            </a>
                                                        </div>
                                                        <div className="btn12 ms-2">
                                                            <a href={`tel:${ad.formData['Contact-Number']}`}>
                                                                <i className="bi bi-telephone"></i>
                                                                <span className='ms-1'>Call Us</span>
                                                            </a>
                                                        </div>
                                                        <div className="btn13 ms-2">
                                                            <Link to={`/ad/${toUrlFormat(ad.formData['Business-Name'])}`}>
                                                                <i className="bi bi-eye"></i>
                                                                <span className='ms-1'>View Details</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <SearchAtNowFooter />
        </div>
    );
}

export default SearchAtNowListing;
