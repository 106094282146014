import React, { useEffect, useState } from 'react';
import axios from 'axios';
function SearchAtNowSignin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    // ... Your login logic

    const handleLogin = async (event) => {
        event.preventDefault();
    
        try {
            const response = await fetch('https://searchatnow.com/api/api/vendors/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password,
                }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                const user = data.user;
    
                // Check if user status is active
                if (user.status === 'active') {
                    // Save user data in localStorage
                    localStorage.setItem('user', JSON.stringify(user));
    
                    // Redirect to the dashboard after successful login
                    window.location.href = '/dashboard';
                } else {
                    // Handle inactive user (status !== 'active')
                    setError('Your account is not active. Please contact support.');
                }
            } else {
                // Handle login failure, display an error message, etc.
                setError(data.message || 'Login failed');
            }
    
        } catch (error) {
            console.error('Error during login:', error);
            setError('Internal Server Error');
        }
    };
    
    useEffect(() => {
        axios.get('https://searchatnow.com/api/api/admin/colors')
            .then(response => {
                const primaryColor = response.data.primaryColor;
                document.documentElement.style.setProperty('--primary-color', primaryColor);
            })
            .catch(error => console.error(error));
    }, [])
    const backgroundImageStyle = {
        background: "background: url('img/sign-up-in-bg.svg') center center/cover no-repeat;",
        /* other styles for your component go here */
    };

    return (
        <div>
            {/* <!-- breedcrumb section start  --> */}
            <section className="breedcrumb" style={backgroundImageStyle}>
                <div className="container">
                    <h2 className="breedcrumb__title text--heading-2">Sign In</h2>
                    <ul className="breedcrumb__page">
                        <li className="breedcrumb__page-item">
                            <a href="index.html" className="breedcrumb__page-link text--body-3">Home</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">Account</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">Sign In</a>
                        </li>
                    </ul>
                </div>
            </section>
            {/* <!-- breedcrumb section end  --> */}

            {/* <!-- registration section start   --> */}
            <section className="section registration">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-0">
                            <div className="registration__features">
                                <div className="registration__features-item">
                                    <span className="icon textColor--primary-4">
                                        <img src="img/chart.svg" alt="chart" />
                                    </span>
                                    <div className="registration__features-info">
                                        <h2 className="text--body-2-600">Manage Your Ads</h2>
                                        <p className="text--body-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam commodo vel ligula.</p>
                                    </div>
                                </div>
                                <div className="registration__features-item">
                                    <span className="icon">
                                        <img src="img/chart-circle.png.svg" alt="chart" />
                                    </span>
                                    <div className="registration__features-info">
                                        <h2 className="text--body-2-600">Chat with Anyone, Anytime</h2>
                                        <p className="text--body-3">Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                                    </div>
                                </div>
                                <div className="registration__features-item">
                                    <span className="icon">
                                        <img src="img/chart.png.svg" alt="chart" />
                                    </span>
                                    <div className="registration__features-info">
                                        <h2 className="text--body-2-600">245,365 Verified User</h2>
                                        <p className="text--body-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam commodo vel ligula.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-0">
                            <div className="registration-form">
                                <h2 className="text-center text--heading-1 registration-form__title">Sign in</h2>
                                <h2 className="registration-form__alternative-title text--body-3">Sign in With Email</h2>

                                <div className="registration-form__wrapper">
                                    <form onSubmit={handleLogin} method='post'>
                                        <div className="input-field">
                                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter Your Email' />
                                            <span className="icon icon--success">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="20" height="20" rx="10" fill="#27C200" />
                                                    <path d="M14 7L8.5 12.5L6 10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="icon icon--alart">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.29 3.86L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7239C2.83871 20.901 3.18082 20.9962 3.53002 21H20.47C20.8192 20.9962 21.1613 20.901 21.4623 20.7239C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.86C13.5318 3.56611 13.2807 3.32312 12.9812 3.15448C12.6817 2.98585 12.3438 2.89725 12 2.89725C11.6563 2.89725 11.3184 2.98585 11.0188 3.15448C10.7193 3.32312 10.4683 3.56611 10.29 3.86Z"
                                                        fill="#FF4F4F"
                                                    />
                                                    <path d="M12 9V13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12 17H12.01" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="input-field">
                                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Enter Your Password ....' />
                                            <span className="icon icon--eye" onclick="showPassword('password',this)">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#191F33" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path
                                                        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                                        stroke="#191F33"
                                                        stroke-width="1.3"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                            <span className="icon icon--success">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="20" height="20" rx="10" fill="#27C200" />
                                                    <path d="M14 7L8.5 12.5L6 10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                            <span className="icon icon--alart">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M10.29 3.86L1.82002 18C1.64539 18.3024 1.55299 18.6453 1.55201 18.9945C1.55103 19.3437 1.64151 19.6871 1.81445 19.9905C1.98738 20.2939 2.23675 20.5467 2.53773 20.7239C2.83871 20.901 3.18082 20.9962 3.53002 21H20.47C20.8192 20.9962 21.1613 20.901 21.4623 20.7239C21.7633 20.5467 22.0127 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.4471 18.6453 22.3547 18.3024 22.18 18L13.71 3.86C13.5318 3.56611 13.2807 3.32312 12.9812 3.15448C12.6817 2.98585 12.3438 2.89725 12 2.89725C11.6563 2.89725 11.3184 2.98585 11.0188 3.15448C10.7193 3.32312 10.4683 3.56611 10.29 3.86Z"
                                                        fill="#FF4F4F"
                                                    />
                                                    <path d="M12 9V13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12 17H12.01" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </div>

                                        <div className="registration-form__option d-flex justify-content-center">


                                            <div className="registration-form__forget-pass text--body-4  ">
                                                <a href="#" className="textColor--primary-4">Forget Password</a>
                                            </div>
                                        </div>

                                        <button className="btn btn--lg w-100 registration-form__btns bgcolor--primary-4 " type="submit">
                                            Sign In
                                            <span className="icon--right">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.75 12H20.25" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M13.5 5.25L20.25 12L13.5 18.75" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </button>
                                        {error && <p className="error-message">{error}</p>}
                                        <p className="text--body-3 registration-form__redirect">Don't Have Account ?
                                            <a href="" className="textColor--primary-4">Sign Up</a>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- registration section--> */}
        </div>
    )
}

export default SearchAtNowSignin