import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import SearchAtNowNavBar from './SearchAtNowNavBar';
import SearchAtNowFooter from './SearchAtNowFooter';

function SearchAtNowSubCate() {
  const [subcategories, setSubcategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { city, categoryName, detailedArea } = useParams();
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [footerContent, setFooterContent] = useState(null);

  useEffect(() => {
    if (categoryName) {
      axios.get(`https://searchatnow.com/api/api/admin/subcategories/byCategory/${encodeURIComponent(categoryName.toLowerCase())}`)
        .then(response => {
          const activeSubcategories = response.data.filter(subcategory => subcategory.status === 'active');
          setSubcategories(activeSubcategories);
        })
        .catch(error => console.error(error));
    }
  }, [categoryName]);

  useEffect(() => {
    axios.get(`https://searchatnow.com/api/api/admin/categories/${encodeURIComponent(categoryName.toLowerCase())}`)
      .then(response => {
        setCategory(response.data);
        setLoading(false);
      })
      .catch(error => console.error(error));

    axios.get(`https://searchatnow.com/api/api/admin/footer/${encodeURIComponent(categoryName.toLowerCase())}`)
      .then(response => {
        setFooterContent(response.data);
      })
      .catch(error => console.error(error));
  }, [categoryName]);

  const filteredSubcategories = subcategories.filter(subcat =>
    subcat.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toUrlFormat = (text) => {
    return text
      .toLowerCase()
      .trim()
      .replace(/[\s\/]+/g, '-')
      .replace(/[^\w\-]+/g, '')
      .replace(/--+/g, '-');
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="loader-icon">
          <img src="../../src/images/loader.gif" alt="loader" />
        </div>
      </div>
    );
  }

  if (!category) {
    return <div>Category not found</div>;
  }

  const imageUrl = `https://searchatnow.com/api/api/uploads/${category.image}`;

  return (
    <>
      <SearchAtNowNavBar />
      <section>
        <div className="container-fluid">
          <div className="bgimg1" style={{ backgroundImage: `url(${imageUrl})` }}></div>

          <div className='d-flex input9'>
            <div className='ms-'>
              <i className="bi bi-search"></i>
            </div>
            <div className='w-100'>
              <input
                type="text"
                placeholder="Search All Category"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="row cdw4 d-flex justify-content-center align-items-center">
            <div className="col-md-12">
              <div className="row mt-3 mb-5">
                {filteredSubcategories.map(subcat => (
                  <div className="col-md-4" key={subcat._id}>
                    <div className="crr1 d-flex align-items-center">
                      <div className='ms-3 imn'>
                        <i className="bi bi-bag-check"></i>
                      </div>
                      <div>
                        <Link to={`/${encodeURIComponent(city.toLowerCase())}/${encodeURIComponent(categoryName.toLowerCase())}/${encodeURIComponent(detailedArea.toLowerCase())}/${toUrlFormat(subcat.name)}`}>
                          <p>{subcat.name}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {footerContent && (
        <section>
          <div className="container-fluid" style={{ userSelect: 'none' }} onContextMenu={(e) => e.preventDefault()}>
            <div dangerouslySetInnerHTML={{ __html: footerContent.footerContent }} />
          </div>
        </section>
      )}
      <SearchAtNowFooter />
    </>
  );
}

export default SearchAtNowSubCate;
