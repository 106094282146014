import React from 'react'

function PaymentDeatils() {
  return (
    <div>
          <div className="container-fluid">
        <div className="row">
            <div className="col-md-3  bcirclclassNameorder">
                <div className="d-flex  justify-content-evenly  align-items-center border-bottom">
                    <div>
                        <img src="img/jenny-wilson.svg" alt="" className="rounded-circle"/>
                    </div>
                    <div className="pt-2">
                        <h5 className="w-100">Jenny Wilson</h5>
                        <p>Member</p>
                    </div>
                    <a className="adimn-menu bgcolor--primary-3 p-2 textColor--success-9" data-bs-toggle="collapse" href="#collapseExample" role="button"
                        aria-expanded="false" aria-controls="collapseExample"><i className="bi bi-list"></i></a>
                </div>
                <div className="pt-2 collapse" id="collapseExample">
                    <ul className="list-group">
                        <li className="list-group-item " aria-disabled="true">
                            <a href="admin-dashboard.html" className="text-decoration-none text-body-secondary   ">
                                <span className="icon">
                                    <i className="bi bi-border-all"></i>
                                </span>
                                Dashboard
                            </a>
                        </li>
                        <li className="list-group-item">
                            <a href="admin-user.html" className="text-decoration-none text-body-secondary">
                                <span className="icon">
                                    <i className="bi bi-person-fill"></i>
                                </span>
                                User
                            </a>
                        </li>
                        <li className="list-group-item">
                            <a href="admin-vender.html" className="text-decoration-none text-body-secondary">
                                <span className="icon">
                                    <i className="bi bi-people-fill"></i>
                                </span>
                                Verder
                            </a>
                        </li>
                        <li className="list-group-item">
                            <a href="admin-category-list.html" className="text-decoration-none text-body-secondary">
                                <span className="icon">
                                    <i className="bi bi-border-style"></i>
                                </span>
                                Category
                            </a>
                        </li>
                        <li className="list-group-item">
                            <a href="admin-service.html" className="text-decoration-none  text-body-secondary ">
                                <span className="icon">
                                    <i className="bi bi-person-fill-gear"></i>
                                </span>
                                Service
                            </a>
                        </li>
                        <li className="list-group-item">
                            <a href="admin-account-setting.html" className="text-decoration-none text-body-secondary">
                                <span className="icon">
                                    <i className="bi bi-person-square"></i>
                                </span>
                                Account Setting
                            </a>
                        </li>
                        <li className="list-group-item">
                            <a href="admin-payment-details.html" className="text-decoration-none text-body-secondary">
                                <span className="icon">
                                    <i className="bi bi-credit-card-fill"></i>
                                </span>
                                Payment Details
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-md-9  border">
                <table className="table">
                    <thead>
                        <tr>
                            <div className="dashboard__filter">
                                <div className="dashboard__filter-left">
                                    <form action="#">
                                        <div className="input-field">
                                            <input type="text" placeholder="search" />
                                            <button className="icon icon-search textColor--primary-4">
                                                <i className="bi bi-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </tr>
                        <tr>
                            <h5>
                                Payment Details
                            </h5>


                        </tr>
                        <tr>

                            
                            <th scope="col">Verder Name </th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date </th>
                            <th scope="col">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>

                           
                            <td>Vender-1</td>
                            <td> <i className="bi bi-currency-rupee"></i>11220</td>
                            <td>20-11-2003</td>
                            <td>1101</td>
                           
                        </tr>
                        <tr>

                           
                            <td>Vender-1</td>
                            <td> <i className="bi bi-currency-rupee"></i>11220</td>
                            <td>20-11-2003</td>
                            <td>1101</td>
                           
                        </tr>
                        <tr>

                           
                            <td>Vender-1</td>
                            <td> <i className="bi bi-currency-rupee"></i>11220</td>
                            <td>20-11-2003</td>
                            <td>1101</td>
                           
                        </tr>
                        <tr>

                           
                            <td>Vender-1</td>
                            <td> <i className="bi bi-currency-rupee"></i>11220</td>
                            <td>20-11-2003</td>
                            <td>1101</td>
                           
                        </tr>
                    </tbody>
                </table>



            </div>
        </div>

    </div>
    </div>
  )
}

export default PaymentDeatils
