import React, { useState, useEffect } from 'react';

function FormBuilder() {
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch('https://searchatnow.com/api/api/admin/categories');
      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }
      const data = await response.json();
      const categoryNames = data.map(category => category.name);
      setCategories(categoryNames);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchSubcategories = async (selectedCategory) => {
    try {
      const response = await fetch('https://searchatnow.com/api/api/admin/subcategories?category=' + selectedCategory);
      if (!response.ok) {
        throw new Error('Failed to fetch subcategories');
      }
      const data = await response.json();
      const subcategoryNames = data.map(subcategory => subcategory.name);
      setSubcategories(subcategoryNames);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    fetchSubcategories(selectedCategory);
  };

  const handleSubmit = async () => {
    const formData = []; // Assuming you have a way to get form data
    const category = category;
    const subcategory = subcategory; // Assuming you have a way to get the subcategory

    try {
      const response = await fetch('https://searchatnow.com/api/api/forms/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formData, category, subcategory }),
      });

      if (!response.ok) {
        throw new Error('Failed to save form data');
      }

      const data = await response.json();
      console.log('Form data saved successfully:', data);
      alert('Form data saved successfully!');
    } catch (error) {
      console.error('Error saving form data:', error);
      alert('Error saving form data!');
    }
  };

  return (
    <div>
      <form action="" id="form-builder-pages" className="mt-5">
        <div className="container">
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="category" className="form-label">Select Category:</label>
              <select id="category" className="form-select" value={category} onChange={handleCategoryChange}>
                <option value="">Select...</option>
                {categories.map((cat, index) => (
                  <option key={index} value={cat}>{cat}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="subcategory" className="form-label">Select Subcategory:</label>
              <select id="subcategory" className="form-select">
                <option value="">Select...</option>
                {subcategories.map((subcat, index) => (
                  <option key={index} value={subcat}>{subcat}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Remove code related to multiple page functionality */}
          <div id="page-1" className="fb-editor"></div>
        </div>
      </form>

      <div className="save-all-wrap">
        <button id="save-all" type="button" onClick={handleSubmit}>Save All</button>
      </div>
    </div>
  );
}

export default FormBuilder;
