import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminMeta = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('adminToken');
    const [pageName, setPageName] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');

    const handleSave = async () => {
        try {
            // Send a POST request to create or update meta information
            await axios.post('https://searchatnow.com/api/api/admin/meta', {
                pageName,
                metaTitle,
                metaDescription,
            });

            // Optionally, you can clear the form fields or show a success message
            setPageName('');
            setMetaTitle('');
            setMetaDescription('');
        } catch (error) {
            console.error('Error saving meta information:', error);
        }
    };

    useEffect(() => {
        // You may want to fetch existing meta information if editing an existing page
        // For simplicity, this example assumes a new page is being created
    }, []);
    if (!token) {
        navigate('/AdminLogin');
        return null;
    }
    return (
        <div>
            <h2>Manage Meta Information</h2>
            <label>
                Page Name:
                <input type="text" value={pageName} onChange={(e) => setPageName(e.target.value)} />
            </label>
            <br />
            <label>
                Meta Title:
                <input type="text" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
            </label>
            <br />
            <label>
                Meta Description:
                <textarea value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
            </label>
            <br />
            <button onClick={handleSave}>Save</button>
        </div>
    );
};

export default AdminMeta;
