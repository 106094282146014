import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchAtNowHome from './Components/SearchAtNowHome';
import AdminPanel from './Components/AdminPanel';
import SearchAtNowAbout from './Components/SerachAtNowAbout';
import SearchAtNowListing from './Components/SearchAtNowListing';
import SearchAtNowBlog from './Components/SearchAtNowBlog';
import SearchAtNowContact from './Components/SearchAtNowContact';
import SearchAtNowSignin from './Components/SearchAtNowSignin';
import SearchAtNowSignUp from './Components/SearchAtNowSignUp';
import SearchAtNowVendorAdList from './Components/SearchAtNowVendorAdList';
import VenderDashboard from './Components/VenderDashboard';
import VendorService from './Components/VendorService';
import VendorSettings from './Components/VendorSettings';
import AdminDashboard from './Components/AdminDashboard';
import AdminUsers from './Components/AdminUsers';
import AdminVendor from './Components/AdminVendor';
import AdminAddCat from './Components/AdminAddCat';
import AdminSubCat from './Components/AdminSubCat';
import AdminService from './Components/AdminService';
import AdminAccount from './Components/AdminAccount';
import PaymentDeatils from './Components/PaymentDeatils';
import { AuthProvider } from './context/AuthContext';
import AdminLogin from './Components/AdminLogin';
import AdminWebsiteHandle from "./Components/AdminWebsiteHandle"
import SearchAtNowSubCate from "./Components/SearchAtNowSubcate"
import SearchAtNowListingDeatails from './Components/SearchAtNowListingDeatails';
import AdminFooter from './Components/AdminFooter';
import AdminBlog from './Components/AdminBlog';
import AdminAbout from './Components/AdminAbout';
import AdminContact from './Components/AdminContact';
import AdminForm from './Components/AdminFormBuilder';
import AdminBlogList from './Components/AdminBlogList';
import AdminMeta from './Components/AdminMeta';
import AdminFromsdata from './Components/dataform';
import SearchAtNowBlogDetail from './Components/SearchAtNowBlogDetail';


function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<SearchAtNowHome />} />
          <Route path="/data" element={<AdminFromsdata />} />
          <Route path="/about" element={<SearchAtNowAbout />} />
          <Route path="/:city/:categoryName/:detailedArea/:subcategoryName" element={<SearchAtNowListing />} />
          <Route path="/blog/:id" element={< SearchAtNowBlogDetail />} />
          <Route path="/:city/:categoryName/:detailedArea" element={<SearchAtNowSubCate />} />
          <Route path="/AdminFooter" element={<AdminFooter />} />
          <Route path="/AdminAbout" element={<AdminAbout />} />
          <Route path="/AdminContact" element={<AdminContact />} />
          <Route path="/AdminForm" element={<AdminForm />} />
          <Route path="/AdminMeta" element={<AdminMeta />} />
          <Route path="/AdminBlog" element={<AdminBlog />} />
          <Route path="/AdminBlogList" element={<AdminBlogList />} />
          {/* <Route path="/listing/:subcategoryId" element={<RestaurantListing />} /> */}
          <Route path="/ad/:businessName" element={<SearchAtNowListingDeatails />} />
          <Route path="/blog" element={<SearchAtNowBlog />} />
          <Route path="/contact" element={<SearchAtNowContact />} />
          <Route path="/signup" element={<SearchAtNowSignUp />} />
          <Route path="/signin" element={<SearchAtNowSignin />} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/vendorlist" element={<SearchAtNowVendorAdList />} />
          <Route path="/dashboard" element={<VenderDashboard />} />
          <Route path="/myad" element={<VendorService />} />
          <Route path="/vendoraccount" element={<VendorSettings />} />
          <Route path="/Admins" element={<AdminDashboard />} />
          <Route path="/users" element={<AdminUsers />} />
          <Route path="/adminvendor" element={<AdminVendor />} />
          <Route path="/category" element={<AdminAddCat />} />
          <Route path="/subcategory" element={<AdminSubCat />} />
          <Route path="/adminService" element={<AdminService />} />
          <Route path="/account" element={<AdminAccount />} />
          <Route path="/payment" element={<PaymentDeatils />} />
          <Route path="/AdminLogin" element={<AdminLogin />} />
          <Route path="/AdminWebsite" element={<AdminWebsiteHandle />} />
        </Routes>

      </Router>
    </AuthProvider>
  );
}

export default App;
