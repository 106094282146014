import React, { useEffect } from 'react';
import axios from 'axios';
import SearchAtNowNavBar from './SearchAtNowNavBar';
import SearchAtNowFooter from './SearchAtNowFooter';
function SearchAtNowAbout() {

    useEffect(() => {
        axios.get('https://searchatnow.com/api/api/admin/colors')
            .then(response => {
                const primaryColor = response.data.primaryColor;
                document.documentElement.style.setProperty('--primary-color', primaryColor);
            })
            .catch(error => console.error(error));
    }, [])
    const backgroundImageStyle = {
        background: "url('src/images/about-us.svg') center center/cover no-repeat",
        /* other styles for your component go here */
    };
    return (
        <>
            <SearchAtNowNavBar />
            {/* <!-- header section end  -->
    <!-- breedcrumb section start  --> */}
            <section className="breedcrumb" style={backgroundImageStyle}>
                <div className="container">
                    <h2 className="breedcrumb__title text--heading-2">About Us</h2>
                    <ul className="breedcrumb__page">
                        <li className="breedcrumb__page-item">
                            <a href="/" className="breedcrumb__page-link text--body-3">Home</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="/about" className="breedcrumb__page-link text--body-3">About us</a>
                        </li>
                    </ul>
                </div>
            </section>
            {/* <!-- breedcrumb section end  -->

    <!-- about-us section start  --> */}
            <section className="section about-us">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 order-xl-0 order-2">
                            <h2 className="about-us__title text--heading-1">Know more about serachatnow</h2>
                            <p className="text--body-3 about-us__brief">
                                searchatnow is a website where you can buy and sell almost everything.
                                The best deals are often done with people who live in your own
                                city or on your own street, so on searchatnow.com it's easy to buy and
                                sell locally. All you have to do is select your region. It takes you
                                less than 2 minutes to post an ad on searchatnow.com. You can sign up
                                for a free account and post service easily every time. which makes it easy to find exactly what
                                you are
                                looking for.
                            </p>
                        </div>
                        <div className="col-xl-6 order-xl-0 order-1">
                            <div className="about-us__img-wrapper">
                                <img src="src/img/imgnet.svg" alt="about" className="img-fluid" />

                                <a href="#" className="icon yplayer bgcolor--primary-4 " data-autoplay="true" data-vbtype="video ">
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M28.5185 15.1473L10.5215 4.14868C10.3699 4.05604 10.1963 4.00545 10.0187 4.00213C9.8411 3.9988 9.6658 4.04285 9.51085 4.12975C9.3559 4.21665 9.2269 4.34326 9.13711 4.49656C9.04733 4.64985 9 4.8243 9 5.00195V26.9991C9 27.1768 9.04733 27.3512 9.13711 27.5045C9.2269 27.6578 9.3559 27.7844 9.51085 27.8713C9.6658 27.9582 9.8411 28.0023 10.0187 27.9989C10.1963 27.9956 10.3699 27.945 10.5215 27.8524L28.5185 16.8538C28.6647 16.7645 28.7855 16.6391 28.8693 16.4897C28.9531 16.3403 28.9971 16.1718 28.9971 16.0005C28.9971 15.8292 28.9531 15.6608 28.8693 15.5114C28.7855 15.362 28.6647 15.2366 28.5185 15.1473Z"
                                            fill="white" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- about-us section end  --> */}
            <SearchAtNowFooter />

        </>
    )
}

export default SearchAtNowAbout
