// components/AdminPanel.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPanel = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch categories on component mount
    axios.get('https://searchatnow.com/api/api/admin/categories')
      .then(response => setCategories(response.data))
      .catch(error => console.error(error));
  }, []);

  const [logoDetails, setLogoDetails] = useState({
    logoWhite: null,
    logoDark: null,
  });

  const [primaryColor, setPrimaryColor] = useState('');

  useEffect(() => {
    // Fetch logo details and primary color on component mount
    axios.get('https://searchatnow.com/api/api/admin/logo')
      .then(response => setLogoDetails(response.data))
      .catch(error => console.error(error));

    axios.get('https://searchatnow.com/api/api/admin/colors')
      .then(response => setPrimaryColor(response.data.primaryColor))
      .catch(error => console.error(error));
  }, []);

  const handleCategoryChange = () => {
    // Check if the category already exists
    if (categories.some(category => category.name === newCategoryName)) {
      setError('Category already exists');
      return;
    }

    // Create new category on button click
    axios.post('https://searchatnow.com/api/api/admin/categories', { categoryName: newCategoryName })
      .then(response => {
        console.log(response.data);
        // Refresh categories after creating a new one
        axios.get('https://searchatnow.com/api/api/admin/categories')
          .then(response => {
            setCategories(response.data);
            setNewCategoryName('');
            setError('');
          })
          .catch(error => console.error(error));
      })
      .catch(error => console.error(error));
  };

  const handleLogoChange = () => {
    // Create FormData for logo upload
    const formData = new FormData();
    formData.append('logoWhite', logoDetails.logoWhite);
    formData.append('logoDark', logoDetails.logoDark);

    // Update logo details on button click
    axios.put('https://searchatnow.com/api/api/admin/logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => console.log(response.data))
      .catch(error => console.error(error));
  };

  const handleColorChange = () => {
    // Update primary color on button click
    axios.put('https://searchatnow.com/api/api/admin/colors', { primaryColor })
      .then(response => console.log(response.data))
      .catch(error => console.error(error));
  };

  const handleLogoFileChange = (event, logoType) => {
    const file = event.target.files[0];
    setLogoDetails(prevLogoDetails => ({
      ...prevLogoDetails,
      [logoType]: file,
    }));
  };

  const isLogoUploadValid = () => {
    // Check if logoDetails is not null and both logoWhite and logoDark are selected
    return logoDetails && logoDetails.logoWhite && logoDetails.logoDark;
  };


  return (
    <div>
      <h2>Admin Panel</h2>

      {/* Logo Settings */}
      <label htmlFor="logoWhite">Upload Logo (White):</label>
      <input
        type="file"
        id="logoWhite"
        accept="image/*"
        onChange={(e) => handleLogoFileChange(e, 'logoWhite')}
      />

      <label htmlFor="logoDark">Upload Logo (Dark):</label>
      <input
        type="file"
        id="logoDark"
        accept="image/*"
        onChange={(e) => handleLogoFileChange(e, 'logoDark')}
      />

      <button onClick={handleLogoChange} disabled={!isLogoUploadValid()}>Save Logo</button>

      {/* Primary Color Settings */}
      <label htmlFor="colorPicker">Primary Color:</label>
      <input
        type="color"
        id="colorPicker"
        value={primaryColor}
        onChange={(e) => setPrimaryColor(e.target.value)}
      />
      <button onClick={handleColorChange}>Save Color</button>
      <label htmlFor="newCategoryName">New Category:</label>
      <input
        type="text"
        id="newCategoryName"
        value={newCategoryName}
        onChange={(e) => setNewCategoryName(e.target.value)}
      />
      <button onClick={handleCategoryChange}>Create Category</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <h3>Categories:</h3>
      <ul>
        {categories.map(category => (
          <li key={category._id}>{category.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default AdminPanel;
