import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SearchAtNowNavBar from './SearchAtNowNavBar';
import SearchAtNowFooter from './SearchAtNowFooter';
import { useParams } from 'react-router-dom';
import parse from 'react-html-parser';

function SearchAtNowBlogDetail() {
    const [blog, setBlog] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        axios.get(`https://searchatnow.com/api/api/admin/blog/${id}`)
            .then(response => {
                setBlog(response.data);
            })
            .catch(error => console.error('Error fetching blog:', error));
    }, [id]);

    if (!blog) {
        return <div>Loading...</div>;
    }

    const firstImage = blog.images && blog.images.length > 0 ? blog.images[0].replace(/\\/g, '/') : 'default-image-path.jpg';
    const secondImage = blog.images && blog.images.length > 1 ? blog.images[1].replace(/\\/g, '/') : 'default-image-path.jpg';

    return (
        <div>
            <SearchAtNowNavBar />
            {/* <section className="breedcrumb" >
                <div className="container">
                    <h2 className="breedcrumb__title text--heading-2">Single Blog</h2>
                    <ul className="breedcrumb__page">
                        <li className="breedcrumb__page-item">
                            <a className='breedcrumb__page-link text--body-3' href='index.html'>Home</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="blog-list-2.html" className="breedcrumb__page-link text--body-3">Blog List</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">/</a>
                        </li>
                        <li className="breedcrumb__page-item">
                            <a href="#" className="breedcrumb__page-link text--body-3">Single Blog</a>
                        </li>
                    </ul>
                </div>
            </section> */}
            <section className="section single-blog">
                <div className="container">
                    <div className="single-blog__lg-img-wrapper">
                        <img src={`https://searchatnow.com/api/api/${firstImage}`} alt="blog-img" className="img-fluid" />
                    </div>
                    <div className="row single-blog__content">
                        <div className="col-lg-8">
                            <div className="single-blog__blog-content">
                                <h2 className="text--heading-2 single-blog__title">
                                    {blog.title}
                                </h2>
                                <div className="single-blog__content-text text--body-3">
                                    {parse(blog.description)}
                                </div>
                                <div className="single-blog__sm-img-wrapper">
                                    <img src={`https://searchatnow.com/api/api/${secondImage}`} alt="blog-img" className="img-fluid" />
                                </div>
                                <div className="single-blog__content-text text--body-3">
                                    {parse(blog.content)}
                                </div>
                            </div>
                            <section className="promo-link mt-0">
                                <div className="container">
                                    <a href="#">
                                        <img src="src/images/promo-img-02.png" alt="" className="w-100" />
                                    </a>
                                </div>
                            </section>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog__sidebar">
                                <span className="toggle-icon">
                                    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.5 13.5H4.5C4.08579 13.5 3.75 13.8358 3.75 14.25V18C3.75 18.4142 4.08579 18.75 4.5 18.75H19.5C19.9142 18.75 20.25 18.4142 20.25 18V14.25C20.25 13.8358 19.9142 13.5 19.5 13.5Z"
                                            stroke="currentColor"
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                        <path
                                            d="M19.5 5.25H4.5C4.08579 5.25 3.75 5.58579 3.75 6V9.75C3.75 10.1642 4.08579 10.5 4.5 10.5H19.5C19.9142 10.5 20.25 10.1642 20.25 9.75V6C20.25 5.58579 19.9142 5.25 19.5 5.25Z"
                                            stroke="currentColor"
                                            stroke-width="1.6"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>
                                    </svg>
                                </span>
                                <div className="blog__sidebar-wrapper">
                                    {/* Search */}
                                    <div className="blog__sidebar-search blog__sidebar-item">
                                        <h2 className="text--body-2-600 item-title">Search</h2>
                                        <div className="input-field">
                                            <input type="text" placeholder="Search" />
                                            <span className="icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                                                        stroke="#00AAFF"
                                                        stroke-width="1.6"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path d="M21.0004 21.0004L16.6504 16.6504" stroke="#00AAFF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="blog__sidebar-item blog-promo__img">
                                        <a href="#">
                                            <img src="src/images/promo-img-01.png" alt="" className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SearchAtNowFooter />
        </div>
    )
}

export default SearchAtNowBlogDetail;
